<template>
  <tj-dialog
    v-model="visible"
    :title="title"
    @close="onClose"
  >
    <el-form ref="formRef" label-width="auto" :model="form" :rules="rules">
      <el-form-item label="名称：" prop="name">
        <el-input v-model.trim="form.name"></el-input>
      </el-form-item>
      <el-form-item label="分类：" prop="goods_category_id">
        <el-cascader
          v-model="category"
          class="wf"
          filterable
          :options="categoryList"
          :props="categoryProps"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="品牌：" prop="goods_brand_id">
        <el-select
          v-model.number="form.goods_brand_id"
          class="wf"
          filterable
        >
          <el-option
            v-for="item in brandList"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="成本价：">
        <el-input-number v-model.number="form.cost_price" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item label="销售价：">
        <el-input-number v-model.number="form.price" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item label="预估价：">
        <el-input v-model.trim="form.appraise" placeholder="用户预估价，如不填写则与销售价一致"></el-input>
      </el-form-item>
      <el-form-item label="单位：">
        <el-input v-model.trim="form.unit"></el-input>
      </el-form-item>
      <el-form-item label="价格提示：">
        <el-input type="textarea" v-model.trim="form.tips"></el-input>
      </el-form-item>
      <el-form-item label="保修期：">
        <el-input-number v-model.number="form.warranty_day" :min="0"></el-input-number>
        <template v-if="form.warranty_day"> 天</template>
        <small v-else style="font-size: 12px;">（无保修期）</small>
      </el-form-item>
      <template v-if="form.type === 2">
        <el-form-item label="故障类型：">
          <el-radio-group v-model="form.fault_type">
            <el-radio-button
              v-for="(label, key) in faultTypes"
              :key="key"
              :label="key"
            >{{ label }}</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="服务类型：">
          <el-radio-group v-model="form.service_type">
            <el-radio-button
              v-for="(label, key) in serviceTypes"
              :key="key"
              :label="key"
            >{{ label }}</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </template>
      <el-form-item label="备注：">
        <el-input type="textarea" v-model="form.remarks"></el-input>
      </el-form-item>
      <el-form-item label="状态：">
        <el-radio-group v-model="form.status">
          <el-radio-button :label="1">上架</el-radio-button>
          <el-radio-button :label="0">下架</el-radio-button>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </template>
  </tj-dialog>
</template>

<script setup>
import { ref, computed, nextTick } from 'vue';
import { groupBy, map } from 'lodash';
import { useDialog } from '@tj/ui';
import request from '@/hooks/request';
import types from '../types';
import faultTypes from './faultTypes';
import serviceTypes from './serviceTypes';

const emit = defineEmits(['submit']);

const defaultGoodsInfo = {
  id: 0,
  name: '',
  type: 1,
  goods_category_id: null,
  goods_brand_id: null,
  cost_price: 0,
  price: 0,
  appraise: '',
  unit: '',
  tips: '',
  warranty_day: 0,
  fault_type: 1,
  service_type: 1,
  remarks: '',
  status: 1,
};

const form = ref({ ...defaultGoodsInfo });
const formRef = ref();

const rules = {
  name: { required: true, message: '商品名称不能为空' },
  goods_category_id: { required: true, message: '商品分类不能为空' },
};

const title = computed(() => {
  if (form.value.id) {
    return '编辑商品';
  }
  return '添加商品';
});

const category = computed({
  get() {
    const {
      type,
      goods_category_id: id,
    } = form.value;
    return [type, id];
  },
  set([type, id]) {
    form.value.type = type;
    form.value.goods_category_id = id;
  },
});
const categoryList = ref([]);
const categoryProps = {
  value: 'id',
  label: 'name',
};
function getCategory() {
  request.get('/admin/goods/category').then((data) => {
    const group = groupBy(data, 'type');
    categoryList.value = map(group, (children, key) => ({
      id: Number(key),
      name: types[key],
      children,
    }));
  });
}

const brandList = ref([]);
function getBrand() {
  request.get('/admin/goods/brand').then((data) => {
    brandList.value = data;
  });
}

const {
  visible,
  open,
  close,
  onOpen,
} = useDialog();
onOpen((id) => {
  getCategory();
  getBrand();
  if (id) {
    request.get(`/admin/goods/goods/${id}`).then((data) => {
      form.value = data;
    });
  } else {
    form.value = { ...defaultGoodsInfo };
  }
  nextTick(() => {
    formRef.value.clearValidate();
  });
});
function onClose() {
  form.value = { ...defaultGoodsInfo };
}

function submit() {
  formRef.value.validate((valid) => {
    if (valid) {
      const { id } = form.value;
      if (id) {
        request.patch(`/admin/goods/goods/${id}`, form.value).then(() => {
          emit('submit');
          close();
        });
      } else {
        request.post('/admin/goods/goods', form.value).then(() => {
          emit('submit');
          close();
        });
      }
    }
  });
}

defineExpose({
  open,
});
</script>
