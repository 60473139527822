<template>
  <div class="app-wrap">
    <el-container class="app-container">
      <el-header height="auto">
        <div class="fr">
          <el-button
            type="primary"
            :icon="CirclePlus"
            @click="editGoods()"
          >添加商品</el-button>
        </div>
      </el-header>
      <el-main>
        <el-table :data="tableData">
          <el-table-column type="expand">
            <template #default="{ row }">
              <el-descriptions class="expand-padding">
                <el-descriptions-item label="价格提示：">{{ row.tips }}</el-descriptions-item>
                <el-descriptions-item label="保修期：">
                  <template v-if="row.warranty_day">
                    {{ row.warranty_day }} 天
                  </template>
                  <template v-else>无</template>
                </el-descriptions-item>
                <template v-if="row.type === 2">
                  <el-descriptions-item label="故障类型：">
                    {{ faultTypes[row.fault_type] }}
                  </el-descriptions-item>
                  <el-descriptions-item label="服务类型：">
                    {{ serviceTypes[row.service_type] }}
                  </el-descriptions-item>
                </template>
                <el-descriptions-item label="备注：">
                  {{ row.remarks }}
                </el-descriptions-item>
              </el-descriptions>
            </template>
          </el-table-column>
          <el-table-column label="名称" prop="name"></el-table-column>
          <el-table-column label="类型" align="center" width="58">
            <template #default="{ row }">
              {{ types[row.type] }}
            </template>
          </el-table-column>
          <el-table-column label="分类" prop="goods_category_name"></el-table-column>
          <el-table-column label="品牌" prop="goods_brand_name"></el-table-column>
          <el-table-column label="成本价" align="right" prop="cost_price"></el-table-column>
          <el-table-column label="销售价" align="right" prop="price"></el-table-column>
          <el-table-column label="预估价" align="right" prop="appraise"></el-table-column>
          <el-table-column label="单位" prop="unit"></el-table-column>
          <el-table-column label="状态" align="center" width="58">
            <template #default="{ row }">
              <el-tag v-if="row.status === 1" type="success">上架</el-tag>
              <el-tag v-else type="info">下架</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="118">
            <template #default="{ row }">
              <el-button
                type="text"
                :icon="Edit"
                @click="editGoods(row.id)"
              >编辑</el-button>
              <el-button
                type="text"
                class="color-danger"
                :icon="Delete"
                @click="remove(row)"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer height="auto">
        <tj-pagination
          v-model="page"
          @change="getList"
        ></tj-pagination>
      </el-footer>
    </el-container>
  </div>
  <EditDialog ref="editDialog" @submit="getList"></EditDialog>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { ElMessageBox } from 'element-plus';
import { CirclePlus, Edit, Delete } from '@element-plus/icons-vue';
import { usePagination } from '@tj/ui';
import EditDialog from './edit.vue';
import request from '@/hooks/request';
import types from '../types';
import faultTypes from './faultTypes';
import serviceTypes from './serviceTypes';

const { page } = usePagination();
const tableData = ref([]);

function getList() {
  request.get('/admin/goods/goods', {
    params: {
      page: page.page,
      per_page: page.limit,
    },
  }).then((data) => {
    tableData.value = data.list;
    page.total = data.total;
  });
}

const editDialog = ref();

function editGoods(goodsId) {
  editDialog.value.open(goodsId);
}

function remove(goods) {
  ElMessageBox.confirm(`删除商品：${goods.name}，是否继续？`, '提示', {
    type: 'warning',
    confirmButtonText: '确定',
    cancelButtonText: '取消',
  }).then(() => {
    request.delete(`/admin/goods/goods/${goods.id}`).then(() => {
      getList();
    });
  });
}

onMounted(() => {
  getList();
});
</script>
